@font-face {
  font-family: 'Metropolis';
  src: url('./fonts/Metropolis-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('./fonts/Metropolis-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('./fonts/Metropolis-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('./fonts/Metropolis-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Metropolis';
  src: url('./fonts/Metropolis-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Nizzoli';
  src: url('./fonts/Nizzoli/Nizzoli-ExtraLight.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nizzoli';
  src: url('./fonts/Nizzoli/Nizzoli-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nizzoli';
  src: url('./fonts/Nizzoli/Nizzoli-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nizzoli';
  src: url('./fonts/Nizzoli/Nizzoli-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nizzoli';
  src: url('./fonts/Nizzoli/Nizzoli-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

/* Roboto */
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
